import {
    Table,
    TableProps,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Text,
    Box,
    Link,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { AdmissionData, columnMap } from './data';
import { useState } from 'react';

import { ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons';


const collapsiableColumns = ['location', 'study_years', 'is_major_college', 'is_technical_college', 'first_early_admissions', 'second_early_admissions', 'regular_admissions'];
const collapseStartColumn = 'location';

interface Props {
    data: AdmissionData[];
    tableFilter: Record<string, any>;
    tableProps?: TableProps;
}

const excludeRowByTableFilter = (
    data: AdmissionData,
    tableFilter: Record<string, any>
): boolean => {
    if (!tableFilter) {
        return false;
    }
    return Object.entries(tableFilter).some(([key, value]) => {
        if (value === null || value === undefined || value === '') {
            return false;
        }

        if (value === "전체") {
            return false;
        }

        if (typeof value === 'string') {
            let splittedValue = value.split(',');
            if (splittedValue.length > 1) {
                return !splittedValue.includes(String(data[key as keyof AdmissionData]));
            }
            return String(data[key as keyof AdmissionData]) !== String(value);
        }
        if (typeof value === 'number') {
            return data[key as keyof AdmissionData] !== value;
        }
        if (typeof value === 'boolean') {
            return data[key as keyof AdmissionData] !== value;
        }
        if (Array.isArray(value)) {
            if (key === 'location') {
                return !value.some((location: { province: string; city: string }) => {
                    if (location.province == "전체" || location.city == "전체") {
                        return true;
                    }
                    return location.province === data.province
                });
            }
            return !value.includes(data[key as keyof AdmissionData]);
        }
    });
};

const generateUniqueKey = (row: AdmissionData): string => {
    // Assuming 'id' is unique; if not, combine multiple properties
    return row.id ? String(row.id) : `${row.university}-${row.year}`;
};

export const MemberTable = ({ data, tableFilter, tableProps }: Props) => {
    const [showColumn, setShowColumn] = useState<boolean>(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<string>("");

    if (!data || data.length === 0) {
        return <Text>데이터가 없습니다.</Text>;
    }

    const columnKeys = Object.keys(data[0]) as (keyof AdmissionData)[];
    const excludeKeys = ['id', 'year', 'created_at', 'updated_at', 'province', 'city', 'university_url'];
    const percentKeys = ['school_records_percentage', 'activity_records_percentage'];

    const displayedColumns = columnKeys.filter((key) => !excludeKeys.includes(key));

    // Filter the data before rendering
    const filteredData = data.filter((row) => !excludeRowByTableFilter(row, tableFilter));

    return (
        <Box overflowX="auto" overflowY="auto" maxHeight={{ "base": "53vh", "sm": "57vh", "md": "65vh", "lg": "72vh" }}
            sx={{
                "::-webkit-scrollbar": {
                    width: "6px", // 스크롤바 너비 조절
                    height: "6px", // 스크롤바 높이 조절
                },
                "::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888",
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                },
            }}>
            <Table {...tableProps}>
                <Thead>
                    <Tr>
                        <Th
                            position="sticky"
                            top={0}
                            left={0}
                            zIndex={4}
                            background="white"
                            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                            borderBottom="2px solid #E2E8F0"
                            m={0}
                            p={1}
                        >
                            <Text w="25px">
                                #
                            </Text>
                        </Th>
                        {displayedColumns.map((key) => {
                            const isUniversityColumn = key === 'university';
                            const isMajorColumn = key === 'major';
                            let left = isUniversityColumn ? 27 : undefined;
                            if (isMajorColumn) {
                                left = 135;
                            }
                            let zIndex = isUniversityColumn || isMajorColumn ? 4 : 2;
                            let width = isUniversityColumn || isMajorColumn ? "60px" : undefined;
                            let columnValue = columnMap[key] || key;
                            return (
                                <Th
                                    key={key}
                                    position="sticky"
                                    top={0}
                                    left={left}
                                    zIndex={zIndex}
                                    background={collapsiableColumns.includes(key) ? "blue.50" : "white"}
                                    boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                                    borderBottom="2px solid #E2E8F0"
                                    w={width}
                                    hidden={!showColumn && collapsiableColumns.includes(key) && key !== collapseStartColumn}
                                    m={0}
                                >
                                    <HStack justify="space-between">
                                        <Text w={width}>
                                            {columnValue}
                                        </Text>
                                        {
                                            key == collapseStartColumn ? (
                                                <IconButton
                                                    size="xs"
                                                    colorScheme='blue'
                                                    variant={showColumn ? "outline" : "solid"}
                                                    aria-label="Toggle Column"
                                                    onClick={() => setShowColumn(!showColumn)}
                                                    icon={showColumn ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                                                />
                                            ) : null
                                        }
                                    </HStack>
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredData.map((row, index) => {
                        const rowKey = generateUniqueKey(row);
                        // 현재 행이 선택된 행인지 판별
                        const isSelected = selectedRowIndex === rowKey;
                        return (
                            <Tr
                                key={rowKey}
                                onClick={() => setSelectedRowIndex((prev) => prev === rowKey ? "" : rowKey)}
                                cursor="pointer"
                            >
                                <Td
                                    position="sticky"
                                    left={0}
                                    zIndex={3}
                                    background="white"
                                    boxShadow="1px 0 2px rgba(0, 0, 0, 0.1)"
                                    m={0}
                                    p={1}
                                    backgroundColor={isSelected ? "orange.100" : "white"}
                                >
                                    <Text w="25px">
                                        {index + 1}
                                    </Text>
                                </Td>
                                {displayedColumns.map((key) => {
                                    const isUniversityColumn = key === 'university';
                                    const isMajorColumn = key === 'major';
                                    let left = isUniversityColumn ? 27 : undefined;
                                    if (isMajorColumn) {
                                        left = 135;
                                    }
                                    let zIndex = isUniversityColumn || isMajorColumn ? 3 : 2;
                                    let width = isUniversityColumn || isMajorColumn ? "60px" : undefined;
                                    return (
                                        isUniversityColumn || isMajorColumn ? (
                                            <Td
                                                key={`${generateUniqueKey(row)}-${key}`}
                                                position="sticky"
                                                left={left}
                                                zIndex={zIndex}
                                                boxShadow="1px 0 2px rgba(0, 0, 0, 0.1)"
                                                w={width}
                                                m={0}
                                                backgroundColor={isSelected ? "orange.100" : "white"}
                                            >
                                                {
                                                    isUniversityColumn && row.university_url ? (
                                                        <Link href={row.university_url} isExternal>
                                                            <Text w={width} whiteSpace="normal" wordBreak="break-word" overflowWrap="break-word">
                                                                {row[key]}
                                                            </Text>
                                                        </Link>
                                                    ) :
                                                        <Text w={width} whiteSpace="normal" wordBreak="break-word" overflowWrap="break-word">
                                                            {row[key]}
                                                        </Text>
                                                }
                                            </Td>
                                        ) : (
                                            <Td
                                                key={`${generateUniqueKey(row)}-${key}`}
                                                hidden={!showColumn && collapsiableColumns.includes(key) && key !== collapseStartColumn}
                                                background={isSelected ? "orange.100" : collapsiableColumns.includes(key) ? "blue.50" : "white"}
                                                m={0}
                                            >
                                                {row[key] === null ? (
                                                    <Text color="gray.500">-</Text>
                                                ) : typeof row[key] === 'boolean' ? (
                                                    row[key] ? (
                                                        <Badge colorScheme="green">O</Badge>
                                                    ) : (
                                                        <Text>-</Text>
                                                    )
                                                ) : percentKeys.includes(key) ? (
                                                    <Text>{row[key]}%</Text>
                                                ) : (
                                                    <Text>{String(row[key])}</Text>
                                                )}
                                            </Td>
                                        )
                                    );
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </Box>
    );
};
