import { Box, Container, VStack } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { NavBar } from "../Common/NavBar";
import { MemberTable } from "./Table";
import { AdmissionData, AdmissionDataExample } from "./data";

import { Filter } from "./Filter";

export function Admission() {
    const [data, setData] = useState<AdmissionData[]>([]);
    const [tableFilter, setTableFilter] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/api/admission/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

                if (!response.ok) {
                    throw new Error(`서버 상태가 이상합니다! ${response.status}`);
                }

                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error(`에러 발생 : ${error}`);
                setData(AdmissionDataExample);
            }
        };
        fetchData();
    }, []);
    return (
        <Box as="section" w="100%" h="100%" 
        sx={{
            "::-webkit-scrollbar": {
                display: "none",
            },
            scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
            msOverflowStyle: "none", // IE에서 스크롤바 숨기기
        }}>
            <NavBar data={data} />
            <VStack align="left" px={{ "base": "3", "lg": "6" }}>
                <Filter tableFilter={tableFilter} setTableFilter={setTableFilter} data={data}></Filter>
                <Box height={{ base: "1", md: "3" }}></Box>
                <MemberTable data={data} tableFilter={tableFilter} />
            </VStack>
        </Box>
    );
}