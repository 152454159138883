import {
    Box,
    HStack,
    Link,
    Heading,
} from '@chakra-ui/react'

import { Logo } from '../../assets/Logo'
import { AdmissionData } from '../AdmissionPage/data'

interface NavBarProps {
    data: AdmissionData[]
}

export function NavBar(props: NavBarProps) {
    const { data } = props
    return (
        <Box as="section" w="full" mb={8}>
            <Box borderBottomWidth="1px" bg="bg.surface" w="full">
                <HStack py={6} px={6} spacing={12}>
                    <Link href="/">
                        <Logo />
                    </Link>
                    {
                        data.length === 0 ?
                            <Heading as="h2" size={{ base: "sm", md: "lg" }}>입시 정보</Heading> :
                            <Heading as="h2" size={{ base: "sm", md: "lg" }}>{data[0]?.year || ""}년 입시 ({data[0]?.year + 1 || ""} 입학)</Heading>
                    }
                </HStack>
            </Box>
        </Box>
    )
}